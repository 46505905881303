/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { ClickAwayListener, Popper, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { bool, object, string } from 'prop-types';
import { getFeatureFlag, getPriceRangeLayout } from '../../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import findContent from '../../../../../../../../gql/queries/findContent';
// import PriceRange from './PriceRange';
// import { getPriceArrays } from '../../../../../../../../helpers/DynamicPricing/getPriceArrays';
import PriceBreakdownDetail from './PriceBreakdownDetail';
import { trackEvent } from '../../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { emitCustomTrackingEvent } from '../../../../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',

    },
    wrapperRight: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '16px',
        '& $dialog': {
            left: 'unset',
            right: '16px',
            '@media screen and (max-width: 440px)': {
                width: '100%',
            },
        },
    },
    messageCopy: {
        color: '#434343',
        fontSize: '12px',
        lineHeight: '20px ',
        letterSpacing: '0.5px',
        cursor: 'pointer',
        textDecoration: 'underline',
        textDecorationThickness: '1px',
        textUnderlineOffset: '3px',
    },
    popperRoot: {
        zIndex: 9999,
    },
    wrapperPDP: {
        paddingRight: '0px',
    },
}));

const PriceBreakDownUi = ({ popperPosition, dynamicPriceData, isPdpRedesignEnabled }) => {
    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const ffEnabled = useSelector(getFeatureFlag('is-price-transparency-enabled'));

    const openPriceDialog = (event) => {
        setAnchorEl(event?.currentTarget);
        setOpen(!isOpen);
    };

    const priceRangeLayout = useSelector(getPriceRangeLayout);
    const productType = dynamicPriceData?.productType?.toLowerCase?.() === 'fpt' ? 'fpt' : 'standard';
    useEffect(() => {
        if (typeof document !== 'undefined') {
            if (anchorEl) {
                document.body.setAttribute('style', 'overflow: hidden');
            } else {
                document.body.setAttribute('style', 'overflow');
            }
        }
    }, [anchorEl]);
    const linkTextEnabled = ffEnabled?.[productType]?.link;
    const priceBreakdownModelEnabled = ffEnabled?.[productType]?.model;
    const isDynamicEnabled = (ffEnabled?.[productType] && (linkTextEnabled || priceBreakdownModelEnabled) && dynamicPriceData?.prices?.[0]?.displayCharges) || false;
    const findContentOptions = {
        brand: 'global',
        contentType: 'ca_product_messaging',
    };

    /**
     * Data will ge get from Apollo cache! It won't query multiple times
     */
    const PRICE_MESSAGE_INFORMATION = findContent(findContentOptions);
    const { data, loading, error } = useQuery(PRICE_MESSAGE_INFORMATION);
    const cmsPricingData = data?.findContent?.content?.entries || [];
    const productDeliveryType = productType === 'fpt' ? 'fpt' : 'Standard';

    const priceMessaging = (cmsPricingData?.[0]?.brand || []).find((item) => item.brand_id === dynamicPriceData?.brand) || {};

    const getDetails = () => priceMessaging?.[productDeliveryType.toLowerCase()] || {};

    const productMessageCopy = getDetails().product_message || '';

    useEffect(() => {
        if (isDynamicEnabled && productMessageCopy) {
            const pricingValue = sessionStorage.getItem('pricingUI');
            if (!pricingValue) {
                dispatch(trackEvent({
                    eventCategory: 'Test Impression',
                    eventAction: 'Honest Pricing UI',
                    eventLabel: 'CA',
                    eventName: 'test_impression',
                    queued: !!(typeof window !== 'undefined' && !window.utag?.data),
                    nonInteraction: true,
                }));

                dispatch(emitCustomTrackingEvent({
                    eventType: 'custom',
                    eventSubType: 'custom',
                    action: 'Impression',
                    category: 'Price Transparency Law',
                    label: 'CA',
                }));
                sessionStorage.setItem('pricingUI', true);
            }
        }
    }, [isDynamicEnabled, productMessageCopy]);

    if (loading) {
        return <></>;
    }

    if (error && !data && !loading) {
        return <></>;
    }

    if (!isDynamicEnabled) {
        return <></>;
    }

    if (!ffEnabled || !dynamicPriceData || !productMessageCopy) {
        return <></>;
    }
    let placement = '';
    switch (popperPosition) {
        case 'right':
            placement = 'bottom-end';
            break;
        default:
            placement = 'bottom-start';
            break;
    }

    return (
        <div className={`${classes.container} ${popperPosition === 'right' ? `${classes.wrapperRight} ${isPdpRedesignEnabled ? classes.wrapperPDP : '' }` : ''}`}>
            <div role="presentation" aria-label="Click here to open popper" onClick={(e) => openPriceDialog(e)} className={classes.messageCopy}>{productMessageCopy}</div>
            {isOpen && priceBreakdownModelEnabled
                ? (
                    <div className={classes.container}>
                        <ClickAwayListener
                            mouseEvent="onMouseDown"
                            touchEvent="onTouchStart"
                            onClickAway={() => {
                                setOpen(false);
                                if (typeof document !== 'undefined') {
                                    document.body.removeAttribute('style', 'overflow');
                                }
                                setAnchorEl(null);
                            }}
                        >
                            <Popper
                                className={classes.popperRoot}
                                open={isOpen}
                                anchorEl={anchorEl}
                                placement={placement}
                                transition
                            >
                                <PriceBreakdownDetail
                                    dynamicPriceData={dynamicPriceData}
                                    priceMessaging={priceMessaging}
                                    productDeliveryType={productDeliveryType}
                                    priceRangeLayout={priceRangeLayout}
                                    openPriceDialog={openPriceDialog}
                                />
                            </Popper>
                        </ClickAwayListener>
                    </div>
                )
                : <></>}
        </div>

    );
};

PriceBreakDownUi.propTypes = {
    dynamicPriceData: object.isRequired,
    popperPosition: string,
    isPdpRedesignEnabled: bool,
};
PriceBreakDownUi.defaultProps = {
    popperPosition: '',
    isPdpRedesignEnabled: false,
};
export default PriceBreakDownUi;
